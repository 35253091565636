import moment from "moment";

const makeid = (length) => {
    var result           = '';
    var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for ( var i = 0; i < length; i++ ) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}

const convertPhoneNumber = (phone) => {
    if (!phone) {
        return  "";
    }

    if (phone.length === 9) {
        return "972-" + phone.substr(1,1) + "-" + phone.substr(2,7);
    }

    if (phone.length === 10) {
        return "972-" + phone.substr(1,2) + "-" + phone.substr(3,7);
    }

    return "972-" + phone;
}

const convertDate = (d) => {
    let str = "";
    try {
        const md = moment(d);
        str = md.format("DD.MM.YYYY");
    }
    catch (e) {
        console.error(e);
      return d;
    }

    return str;
}

export {makeid, convertPhoneNumber, convertDate}