import React from 'react'
import {inject, observer} from "mobx-react";

@inject("timeStore") @observer
class Time extends React.Component {
    render() {
        return (
            <div>{this.props.timeStore.time}</div>
        )
    }
}

export {Time}