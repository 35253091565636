import React from 'react';
import {observer, inject} from "mobx-react";
import {Modal, Button} from "react-bootstrap";

@inject("submitModal") @observer
class SubmitModal extends React.Component {

    render() {
        const s = this.props.submitModal;

        return (
            <Modal
                show={s.isOpen}
                onHide={() => {s.closeModal()}}
                backdrop="static"
            >
                <Modal.Header closeButton>
                    <Modal.Title />
                </Modal.Header>
                <Modal.Body>
                    הטופס נשלח בהצלחה, אנו נשוב אליכם בהקדם.
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="primary"
                        onClick={() => {s.closeModal()}}
                    >
                        אישור
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }
}

export {SubmitModal}