import {observable} from "mobx";
import {makeid} from "utils/common";

const FieldTypes = {
    "Text" : "Text",
    "Email" : "Email",
    "Number" : "Number",
    "Date" : "Date"
};


class Field {
    @observable value = "";
    @observable isValid = true;
    @observable name = "";
    @observable title = "";
    @observable type = "";
    @observable required = false;
    @observable placeholder = "";


    constructor(type = FieldTypes.Text, title = "", required = false, placeholder = null) {
        this.type = type;
        this.title = title;
        this.required = required;
        this.placeholder = placeholder || title;

        this.id = makeid(8);
    }

    setValue (value) {
        this.value = value;
        this.isValid = true;
    }
}

class TextField extends Field {
    constructor(title, required, placeholder = null) {
        super(FieldTypes.Text, title, required, placeholder);
    }
}

class EmailField extends Field {
    constructor(title, required) {
        super(FieldTypes.Email, title, required);
    }
}

class DateField extends Field {
    constructor(title, required) {
        super(FieldTypes.Date, title, required);
    }
}


export {FieldTypes, Field, TextField, DateField, EmailField}