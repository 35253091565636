import React from 'react';
import {useParams} from "react-router-dom";


function QRCodePage () {
    let { token } = useParams();
    console.log("token",token)
    return (<div>
        <div className={"row"}>
            <div className={"col-md-12"}></div>
        </div>

        <div className={"row"}>
            <div className={"col-md-12"}>
                <img style={{"margin":"auto","maxWidth":"100%"}} src={process.env.REACT_APP_API_URL + "/qrcode/"+token}/>
            </div>
        </div>

    </div>)
}
export {QRCodePage};