import {observable, action} from "mobx";


class ModalStore {
    @observable isOpen = false;

    @action openModal() {
        this.isOpen = true;
    }

    @action closeModal() {
        this.isOpen = false;
    }
}

export {ModalStore}