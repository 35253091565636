import React from 'react'
import {Time} from "./time";
import {FullForm} from "./form/full.form";
import {SubmitModal} from "./submit.modal";
import {ValidationErrorModal} from "./validation.error.modal";
import {observer} from "mobx-react";
import {SuccessPage} from "./form/success.page";

import {
    Router,
    Switch,
    Route,
    Link
} from "react-router-dom";
import {PaymentModal} from "components/payment.modal";
import {ErrorPage} from "components/form/error.page";
import {history} from "components/services/custom.router";
import {QRCodePage} from "components/qrcode.page";
import {SuccessPageLocal} from "components/form/success.page.local";

@observer
class Page extends React.Component {
    render() {
        return (
            <Router history={history} basename={process.env.REACT_APP_BASE_ROUTE}>
                <div className={"container"}>
                    <div className={"row"}>
                        <div className={"col-md-2"}></div>
                        <div className={"col-md-8"}>
                            <div className={"row"}>
                                <div className={"col-md-2"}>
                                    <div className={"kv-logo"}/>
                                </div>
                            </div>

                            <Switch>
                                <Route path="/" exact={true}>

                                    <FullForm/>
                                </Route>

                            </Switch>

                            <Route path={"/success"} >
                                <SuccessPage/>
                            </Route>

                            <Route path={"/success-page"} >
                                <SuccessPageLocal/>
                            </Route>

                            <Route path={"/error"}>
                                <ErrorPage/>
                            </Route>

                            <Route path={"/qrcode/:token"}>
                                <QRCodePage/>
                            </Route>
                        </div>
                    </div>
                    <SubmitModal/>
                    <ValidationErrorModal/>
                    <PaymentModal/>
                </div>



            </Router>
        )
    }
}

export {Page}