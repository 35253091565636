import React from 'react'

class SuccessPageLocal extends React.Component {
    render() {
        return (
            <div className={"row"}>
                <div className={"col-md-12"}>
                    <h1>צוות Blue Bird מאחל לכם חופשה מהנה וטיסה בטוחה ונעימה.</h1>
                    <p>לפניות בנוגע לשירות שהזמנתם ניתן לפנות במייל <a href="mailto:service@kavei.co.il">service@kavei.co.il</a> ואנו נשוב אליכם בהקדם.
                    </p>
                    <p>
                        צוות Blue Bird
                    </p>

                </div>
            </div>
        )
    }
}

export {SuccessPageLocal}