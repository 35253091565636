import access from 'safe-access';
import {observable, action, computed} from "mobx";
import {isBlankString, isEmailValid, isEnglishOnly, isPhoneNumberValid, isAlphaNumeric} from "../utils/validator";
import axios from 'axios';
import {makeid, convertPhoneNumber, convertDate} from "../utils/common";
import {FieldTypes, TextField, DateField, EmailField} from "stores/fields";
import moment from "moment";
import {Person} from "stores/person";
import {createMomentDateWithFixedOffset} from "utils/time.service";

const DEFAULT_CONFIG = { headers: { "Content-Type": "application/json" } };
const MULTIPART_CONFIG = { headers: { "Content-Type": "multipart/form-data" } };
const URLENCODED_CONFIG = { headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' } }

const SERVER_ERROR_MESSAGE = "שגיאת שרת, אנא נסו מאוחר יותר";
const SERVICE_NOT_AVAILABLE_FOR_PNR = "לא ניתן להזמין שירות עבור הזמנה זו";

function fixedEncodeURIComponent (str) {
    return encodeURIComponent(str).replace(/[!'()*]/g, function(c) {
        return '%' + c.charCodeAt(0).toString(16);
    });
}

function getCurrencySymbolByCode(code) {
    switch (code) {
        case "EUR":
            return "€";
        case "USD":
            return "$";
        case "NIS":
            return "₪";

        default:
            return "";
    }
}

function getSuitableFlight(flights = []) {
   // const flights = access(this.pnrObject,'data.flightsDetails') || [];
    const flight = flights.find((f) => {
        return (access(f,'to.code') === 'TLV');
    });

    return flight;
}

function getAllowedCitiesArray(prices = []) {
    let a = [];

    prices.forEach((p) => {
        a = a.concat(p.cities || []);
    });

    return a.filter((item, pos, self) => {
        return self.indexOf(item) === pos;
    })
}

class FormStore {

    @observable orderNumber = new TextField("מספר הזמנה", true, "הכנס מספר הזמנה");
    @observable selectedProduct = null;

    @observable pnrObject = null;


    // payment information
    @observable ccName = new TextField("שם מלא באנגלית", true);
    @observable ccEmail = new EmailField("דואר אלקטרוני", true);
    @observable ccPhone = new TextField("טלפון נייד", true);

    @observable userConfirmed = false;
    @observable isSubmitted = false;
    @observable validationMessages = [];
    @observable serverTime = null;

    @observable ref = "";
    @observable prices = [];
    @observable currencies = [];

    @observable paymentUrl;
    @observable iframeResult;

    @observable isLoading = false;
    @observable isLoaded = false;
    @observable loadErrorMessage = "";


    constructor(root) {
        this._root = root;

        this.fetchServerTime();
        this.fetchCurrencies();
        this.fetchPrices();

        if (process.env.REACT_APP_DUMMY_DATA) {
            this.ccName.setValue("Test Name");
            this.ccEmail.setValue("test@test.com");
            this.ccPhone.setValue("0555555555");

            this.orderNumber.setValue('50096714');
        }

    }

    @action fetchCurrencies() {
        const url = process.env.REACT_APP_API_URL + "/api/currencies";

        axios.get(url, DEFAULT_CONFIG)
            .then((res) => {
                console.log("fetchCurrencies", res.data);
                this.currencies.replace(res.data);
            })
            .catch((e) =>{
                console.error(e);
            })
    }

    @action setProduct(product) {
        this.selectedProduct = product;
    }

    @computed get selectedProductId() {
        if (this.selectedProduct) {
            return this.selectedProduct._id;
        }

        return null;
    }

    @action fetchOrderData() {
        if (!this.orderNumber.value) {
            return;
        }

       //const url = process.env.REACT_APP_CUSTOMER_SERVICE_URL + '/api/v1/info/pax-count?pnr=' + this.orderNumber.value;
        const url = process.env.REACT_APP_API_URL + '/api/pnr/' + this.orderNumber.value;
        //const url = '/data/pnr1.json';

        this.isLoading = true;
        this.isLoaded = false;
        this.loadErrorMessage = "";
        this.pnrObject = null;
        this.userConfirmed = false;
        this.selectedProduct = null;

        axios.get(url)
            .then((response) => {
                console.log(response);
                this.pnrObject = response.data;

                const status = access(response,'data.status');
                const errorCode = access(response,'data.error[0].code');
                const serverMessage = access(response,'data.error[0].message');
                let finalMessage = "";

                if (!access(response,'data.data.isBlueBirdGreeceToTlv')) {
                    finalMessage = SERVICE_NOT_AVAILABLE_FOR_PNR;
                }

                if (status !== 'OK') {
                    switch (errorCode) {
                        case "INVALID_PARAMETERS":
                            finalMessage = "הזמנה לא קיימת";
                            break;

                        default:
                            finalMessage = SERVICE_NOT_AVAILABLE_FOR_PNR;//serverMessage || SERVER_ERROR_MESSAGE;
                    }


                }
                this.loadErrorMessage = finalMessage;
            })
            .catch((e) => {
                console.error(e);
                this.loadErrorMessage = "שגיאת שרת, אנא נסו מאוחר יותר";
            })
            .finally(() => {

                setTimeout(() => {
                    this.isLoading = false;
                    this.isLoaded = true;
                },process.env.REACT_APP_LOAD_DELAY);

            });
    }

    @computed get isPnrObjectValid() {
        let isValid = true;

        if (!this.pnrObject) {
            isValid = false;
        }

        const pnr = access(this.pnrObject,'data.pnr');
        if (!pnr || this.numberOfPassengers < 1) {
            isValid = false;
        }

        /*
        if (!access(this.pnrObject,'data.isBlueBirdGreeceToTlv')) {
            isValid = false;
        }

         */

        if (!this.isServiceAvailable) {
            isValid = false;
        }

        return isValid;
    }

    @computed get numberOfPassengers() {
        if (!this.pnrObject) {
            return 0;
        }

        return parseInt(access(this.pnrObject,'data.numberOfPassengersWithoutInfants') || 0, 10);
    }

    @computed get canSelectProduct() {
        return this.isPnrObjectValid && this.userConfirmed;
    }

    @action fetchServerTime() {
        const d = moment(new Date());
        const url = process.env.REACT_APP_API_URL + "/api/server-time";


        axios.get(url,DEFAULT_CONFIG)
            .then((res) => {
                console.log("serverTime", res.data.serverTime);
                this.serverTime = res.data.serverTime;


                const d2 = moment(this.serverTime);

                console.log("time difference",d2.diff(d));

            })
            .catch((e) =>{
                console.error(e);
            })
    }

    @action fetchPrices() {
        const url = process.env.REACT_APP_API_URL + "/api/prices-bluebird-checkin";

        axios.get(url, DEFAULT_CONFIG)
            .then((res) => {
                console.log("fetchPrices", res.data);
                this.prices.replace(res.data);
            })
            .catch((e) =>{
                console.error(e);
            })
    }

    @computed get confirmationMessage() {
        if (!this.pnrObject || this.isLoading || this.loadErrorMessage) {
            return "";
        }

        const flights = access(this.pnrObject,'data.flightsDetails') || [];
        const flight = flights.find((f) => {
            return (access(f,'to.code') === 'TLV');
        });

        const departureTime = createMomentDateWithFixedOffset(access(flight,'deparetureTime'));

        let message = "";
        if (flight) {
            message = `אני מאשר שזו הטיסה שלי: טיסה מ${flight.from.defaultCity.name} ל${flight.to.defaultCity.name} ,תאריך טיסה: ${departureTime.format("DD.MM.YYYY HH:mm")}, נוסעים: ${this.numberOfPassengers}`;
        }
        console.log("confirmationMessage ", message);
        return message;
    }

    @computed get isPnrNumberValid() {
        if (!this.orderNumber.value || this.orderNumber.value.length !== 8 || this.orderNumber.value.trim().length !== 8) {
            return false;
        }

        return true;
    }

    @computed get isFormValid() {
        let isValid = true;

        if (!this.orderNumber.value || this.orderNumber.value.length !== 8) {
            isValid = false;
        }

        if (!this.selectedProduct) {
            isValid = false;
        }

        const allFields = [
            this.ccPhone,
            this.ccName,
            this.ccEmail
        ];

        allFields.map((f) => {
            if (!f.isValid) {
                isValid = false;
            }
        })


        return isValid;
    }

    @action postForm() {
        const d = this.jsonData;

        console.log("jsonData", d);
        const url = process.env.REACT_APP_API_URL + "/api/advanced-form";

        this.iframeResult = "";
        this.isSubmitted = false;

        axios.post(url, d,DEFAULT_CONFIG)
            .then((res) => {
                console.log("res",res);

                const token = access(res, 'data.token');
                if (token) {

                    let url = new URL("https://direct.tranzila.com/bluebird1/iframenew.php");
                    url.searchParams.append('lang', 'il');
                    url.searchParams.append('sum', this.totalPriceInNIS);
                    url.searchParams.append('currency',"1");
                    url.searchParams.append('r1_token',token);


                    url.searchParams.append('email', fixedEncodeURIComponent(this.ccEmail.value));
                    url.searchParams.append('contact', fixedEncodeURIComponent(this.ccName.value));
                    url.searchParams.append('notify_url_address', encodeURI('https://pass.kavei.co.il/api/payment-confirmation/'+token))

                    console.log("tranzila URL", url.toString());

                    this.paymentUrl = url.toString();
                    this._root.paymentModal.openModal();

                } else {
                    // show error modal
                   // this._root.serverErrorModal.openModal();
                }

            })
            .catch((e) =>{
                // show error modal
                console.error(e);
               // this._root.serverErrorModal.openModal();
            })
    }

    @computed get jsonData() {


        const form = {
            "pnr" : this.orderNumber.value,
            "numberOfPassengers" : this.numberOfPassengers,
            "ref"     : this.ref,
            "selectedProduct" : this.selectedProduct,

            "ccName" : this.ccName.value,
            "ccEmail" : this.ccEmail.value,
            "ccPhone" : this.ccPhone.value,
        }

        const payment = {
            "currency" : this.currency,
            "exchangeRate": this.exchangeRate,
            "price" : this.totalPrice,
            "totalPriceInNIS" : this.totalPriceInNIS,
        }

        const d = {
            "application" : "BlueBirdFastCheckIn",
            "form" : form,
            "paymentRequest" : payment
        }

        console.log("fullJSON", d);

        return d;
    }


    @action validateForm() {
        console.log("validateForm");
        const messages = [];

        if (!this.orderNumber.value || this.orderNumber.value.length !== 8) {
            this.orderNumber.isValid = false;
        }


        // payment form
        if (isBlankString(this.ccName.value) || !isEnglishOnly(this.ccName.value)) {
            this.ccName.isValid = false;
        }

        if (!isEmailValid(this.ccEmail.value)) {
            this.ccEmail.isValid = false;
        }


        if (!isPhoneNumberValid(this.ccPhone.value)) {
            this.ccPhone.isValid = false;
        }

        this.validationMessages.replace(messages);
    }

    @computed get totalPrice() {
        if (this.selectedProduct) {
            return  this.selectedProduct.price * this.numberOfPassengers;
        }

        return 0;    }

    @computed get totalPriceInNIS() {
        if (this.selectedProduct) {
            return  parseFloat(this.exchangeRate * this.selectedProduct.price * this.numberOfPassengers).toFixed(2);
        }

        return 0;
    }

    @computed get currency() {
        //return "₪";


        //return getCurrencySymbolByCode(this.currency);

        if (this.selectedProduct) {
            return this.selectedProduct.currency;
        }
        return "";


    }

    @computed get currencySymbol() {
        return getCurrencySymbolByCode(this.currency);
    }

    @computed get exchangeRate() {
        let exchangeRate = 0;
        if (!this.currencies) {
            return 0;
        }

        this.currencies.find((c) => {
            if (c.currencyCode === this.currency) {
                exchangeRate = c.exchangeRate;
            }
        });


        return exchangeRate;
    }

    @computed get filteredPrices() {
        const flight = getSuitableFlight(access(this.pnrObject,'data.flightsDetails'));
        const city = access(flight,'from.code');

        return this.prices.filter((p) =>{
            return (city && p.cities.indexOf(city) >=0)
        })
            .map((p) => {
            console.log("p",p);
            const o = {...p};
            console.log(o);
            o.text = o.text.replaceAll('--PRICE--', getCurrencySymbolByCode(p.currency)+p.price);

            return o;
        });
    }

    @computed get isServiceAvailable() {
        const flight = getSuitableFlight(access(this.pnrObject,'data.flightsDetails'));
        const cities = getAllowedCitiesArray(this.prices);

        let i = cities.findIndex((city, ind) => {
            return city === access(flight,'from.code');
        });

        if (i>=0) {
            console.log("isServiceAvailable", access(flight,'from.code'), cities);
        }

        return i >= 0;
    }

}

export {FormStore,FieldTypes}