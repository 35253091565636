import logo from './logo.svg';
import './App.css';
import {Time} from "./components/time";
import React from 'react';
import {Page} from "./components/page";
import {Button} from 'react-bootstrap';

class App extends React.Component {
  render() {
    return (
        <Page>

        </Page>
    );
  }
}

export default App;
