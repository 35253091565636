import {observable} from "mobx";


class TimeStore {
    @observable time = 0;

    constructor() {

        this.int = setInterval(() => {
            this.time ++;
        },500)
    }
}

export {TimeStore}