const validAreaCodes = ["02","03","04","08", "09", "071", "072", "073", "074", "076", "077", "078", "079", "050", "051",
    "052", "053", "054", "055", "056", "058", "059"];

const isEnglishOnly =  (str) => {
    var r = /^[\sa-zA-Z]+$/;
    return r.test(str);
}

const isEmailValid = (emailStr) => {
    var re = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i;
    return re.test(emailStr);
}

const isBlankString = (str) => {
    return (!str || /^\s*$/.test(str));
}

const isPhoneNumberValid = (phoneStr) => {
    var pattern = /^\d{2,3}\-?\d{7}$/;

    var r = pattern.test(phoneStr);
    return r;
}

const isPhoneAreaCodeValid = (areaCode) => {

    return validAreaCodes.find((code) => {
        return (code === areaCode)
    });
}

const isPhoneNumberWithoutAreaValid = (phoneNumber) => {

    var phoneRegEx = new RegExp("^\\d{7}$");

    if ((phoneRegEx.exec(phoneNumber) != null)) {
        return true;
    }

    return false;
}

const isAlphaNumeric = (inputTxt) => {
    const letterNumber = /^[0-9a-zA-Z]+$/;
    return (inputTxt.match(letterNumber));
}



const validateForEmptyString = (fields = []) => {
    fields.forEach((f) => {
        if (isBlankString(f.value)) {
            f.isValid = false;
        }
    });
}

const validateForEnglish = (fields = []) => {
    fields.forEach((f) => {
        if (!isEnglishOnly(f.value)) {
            f.isValid = false;
        }
    });
}

const isIDNumberValid = (str) => {
    // DEFINE RETURN VALUES
    var R_ELEGAL_INPUT = false;
    var R_NOT_VALID = false;
    var R_VALID = true;

    // Just in case -> convert to string
    var IDnum = str.toString();

    // Validate correct input
    if ((IDnum.length > 9) || (IDnum.length < 2)) {
        return R_ELEGAL_INPUT;
    }

    if (isNaN(IDnum) || isNaN(parseInt(IDnum))) {
        return R_ELEGAL_INPUT;
    }


    // The number is too short - add leading 0000
    if (IDnum.length < 9)
    {
        while (IDnum.length < 9)
        {
            IDnum = '0' + IDnum;
        }
    }

    // CHECK THE ID NUMBER
    var mone = 0, incNum;
    for (var i = 0; i < 9; i++)
    {
        incNum = Number(IDnum.charAt(i));
        incNum *= (i % 2) + 1;
        if (incNum > 9)
            incNum -= 9;
        mone += incNum;
    }
    if (mone % 10 == 0)
        return R_VALID;
    else
        return R_NOT_VALID;
}


export {
    isEnglishOnly,
    isEmailValid,
    isBlankString,
    isPhoneNumberValid,
    isPhoneAreaCodeValid,
    isPhoneNumberWithoutAreaValid,
    isAlphaNumeric,
    validateForEnglish,
    validateForEmptyString,
    isIDNumberValid
}