import React from 'react';
import {observer, inject} from "mobx-react";
import {Modal, Button} from "react-bootstrap";

@inject("validationModal", "formStore") @observer
class ValidationErrorModal extends React.Component {

    render() {
        const s = this.props.validationModal;
        const f = this.props.formStore;


        return (
            <Modal
                show={s.isOpen}
                onHide={() => {s.closeModal()}}
                backdrop="static"
            >
                <Modal.Header closeButton>
                    <Modal.Title />
                </Modal.Header>
                <Modal.Body>
                    <div className="alert alert-warning" role="alert">
                        אנא מלאו את הפרטים. כל השדות הנם שדות חובה
                    </div>

                    {f.validationMessages.map((m,ind) => {
                        return (
                            <div className="alert alert-danger"  key={"error_message_"+ind} role="alert">
                                {m}
                            </div>
                        )
                    })}
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="primary"
                        onClick={() => {s.closeModal()}}
                    >
                        אישור
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }
}

export {ValidationErrorModal}