import React from 'react';
import {Form} from "react-bootstrap";
import {observer} from "mobx-react";
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css';

@observer
class DateField extends React.Component {
    render() {
        if (!this.props.field) {
            return null;
        }
        const f = this.props.field;
        const d = {};
        if (f.required) {
            d.required = true;
        }

        const c = (!f.isValid) ? "dp-invalid" : "";

        return (
            <div className={c}>
                <label className={"ui-label"} htmlFor={f.id}>{f.title}</label><br/>
                <DatePicker
                    showMonthDropdown
                    showYearDropdown
                    minDate={new Date(1900,1,1)}
                    maxDate={new Date(2035, 1,1)}
                    dateFormat="dd/MM/yyyy"
                    selected={f.value}
                    onSelect={(date) => {
                        console.log("!",date);

                    }} //when day is clicked
                    onChange={(date) => {
                        console.log(date);
                        f.setValue(date);
                    }} //only when value has changed
                    {...d}
                    isInvalid={!f.isValid}
                />
            </div>
        )
    }
}

DateField.defaultProps = {
    field : null
}

export {DateField}