import React from 'react'
import {TextField} from "./text.field";
import {FieldTypes} from "../../stores/form.store";
import {DateField} from "./date.field";

class FormField extends React.Component {
    render() {
        if (!this.props.field) {
            return null;
        }

        switch (this.props.field.type) {
            case FieldTypes.Number:
            case FieldTypes.Email:
            case FieldTypes.Text:
                return <TextField {...this.props}/>
            case FieldTypes.Date:
                return <DateField {...this.props}/>
            default:
                return null;
        }
    }
}

FormField.defaultProps = {
    field : null,
    direction : "rtl"
}

export {FormField}