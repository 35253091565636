import React from 'react'

class SuccessPage extends React.Component {
    render() {
        return (
            <div className={"row"}>
                <div className={"col-md-12"}>
                    <h1>התשלום התקבל בהצלחה!</h1>
                    <p>לקוחות יקרים, בדקות הקרובות יישלח אליכם אישור לכתובת הדוא"ל, אותו עליכם להציג עם ההגעה לשדה התעופה (אין צורך להדפיס, ניתן להציג את המייל בטלפון הנייד).
                    </p>
                    <p>בברכת חופשה נעימה,<br/>

                        צוות Blue Bird
                    </p>

                </div>
            </div>
        )
    }
}

export {SuccessPage}