import {action, computed, observable} from "mobx";
import {convertDate, convertPhoneNumber} from "utils/common";
import {isBlankString, isIDNumberValid, isPhoneNumberValid} from "utils/validator";
import {TextField, DateField, EmailField} from "stores/fields";
import {validateForEmptyString, validateForEnglish} from "utils/validator";

class Person {
    @observable lastName = new TextField("שם משפחה (אנגלית בלבד)", true);
    @observable middleName = new TextField("שם אמצעי (אנגלית בלבד)", false);
    @observable firstName =  new TextField("שם פרטי (אנגלית בלבד)", true);
    @observable title = "";

    @observable age = "Adult";
    @observable gender = "Male";

    @observable passportNumber =  new TextField("מספר ת.ז.", true);
    @observable birthDate = new DateField("תאריך לידה", true);
    @observable mobilePhone = new TextField("טלפון נייד", true);

    constructor(parent) {
        this._parent = parent;

        this.allFields = [];
        this.allFields.push(this.lastName);
        this.allFields.push(this.middleName);
        this.allFields.push(this.firstName);
        this.allFields.push(this.birthDate);

        this.allFields.push(this.passportNumber);
        this.allFields.push(this.mobilePhone);

        if (process.env.REACT_APP_DUMMY_DATA) {
            this.lastName.setValue("LastName");
            this.middleName.setValue("MiddleName");
            this.firstName.setValue("FirstName");
            const date = new Date()
            date.setDate(date.getDate()-365*20);
            this.birthDate.setValue(date);
            this.passportNumber.setValue("123123123");
            this.mobilePhone.setValue("0555555555");
        }
    }

    @computed get isFormValid() {
        let isValid = true;

        this.allFields.map((f) => {
            if (!f.isValid) {
                isValid = false;
            }
        });

        return isValid;
    }

    @computed get jsonData() {
        const d = {
            "lastName" : this.lastName.value,
            "middleName" : this.middleName.value,
            "firstName" : this.firstName.value,
            "age" : this.age,
            "birthDate" : convertDate(this.birthDate.value),
            "gender" : this.gender,
            "passportNumber" : this.passportNumber.value,
            "mobilePhone" : convertPhoneNumber(this.mobilePhone.value),
        }

        return d;
    }

    @action copyData() {
        console.log("copy data", this);

        if (this.mobilePhone.value === "") {
            this.mobilePhone.setValue(this._parent.mobilePhone.value);
        }
    }

    @action setGender(newValue) {
        console.log("setGender", newValue);
        this.gender = newValue;
    }

    @action setAge (newValue) {
        console.log("setAge", newValue);
        this.age = newValue;
    }

    @action validateForm() {
        console.log("person validate");

        validateForEnglish([
            this.lastName,
            this.firstName
        ])

        if (!isIDNumberValid(this.passportNumber.value)) {
            this.passportNumber.isValid = false;
        }

        if (!isPhoneNumberValid(this.mobilePhone.value)) {
            this.mobilePhone.isValid = false;
        }


        if (isBlankString(this.birthDate.value)) {
            this.birthDate.isValid = false;
        }
    }
}

export {Person}