import React from 'react'
import {inject, observer} from "mobx-react";
import {Dropdown, Button, Form, Modal} from "react-bootstrap";
import {FormField} from "./field";
import {PaymentForm} from "./payment.form";

@inject ("formStore","validationModal") @observer
class FullForm extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            validated : false
        }
    }
    render() {
        const s = this.props.formStore;

        const handleSubmit = (event) => {
            const form = event.currentTarget;
            if (form.checkValidity() === false) {
                event.preventDefault();
                event.stopPropagation();
            }

            this.setState({
                validated : true
            });

            s.validateForm();

            event.preventDefault();
            event.stopPropagation();
            //setValidated(true);


            if (!s.isFormValid) {
                this.props.validationModal.openModal();
                return;
            }

            s.postForm();
        };

        if (s.isSubmitted) {
            return (
                <div className="alert alert-success" role="alert">
                    הטופס נשלח בהצלחה, אנו נשוב אליכם בהקדם.
                </div>

            )
        }


        return (
            <div>
                <Form noValidate onSubmit={handleSubmit}>

                <div className={"row"}>
                    <div className={"col-md-12"}>
                        <h1>רוצים להימנע מהמתנה ארוכה בתורים בטיסתכם חזרה ארצה ?
                            יש לנו פתרון!</h1>

                        <p>לקוחות יקרים, כחלק מהמאמץ לקצר עבורכם את זמני ההמתנה, חברת Blue Bird גאה להשיק שירות למעבר מהיר וקיצור התורים ביעדים נבחרים.
                        </p>

                        <p>בעזרת שרות פריוריטי פס תוכלו להימנע מהמתנה בתורים הארוכים והמתישים בדרככם חזרה ארצה. בשלב הזה השירות אינו פעיל בשדה התעופה בן-גוריון.</p>
                        <h2>איך זה עובד ?</h2>
                        <p>
                            במידה ומספר הזמנתכם מתחיל בספרה 5 אנא <a href="https://my.kavei.co.il">לחצו כאן</a>
                        </p>
                        <p>
                            במידה ומספר הזמנתכם מתחיל במספר "305" אנא <a href="https://booking.bluebirdair.com/Find-Reservation/Find-my-reservation">לחצו כאן</a>
                        </p>
                    </div>
                </div>







                <div className={"row"}>
                    <div className={"col-md-12"}><br/></div>
                </div>
                </Form>
        </div>);

    }
}

export {FullForm}